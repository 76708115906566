import React from 'react';
import Card from 'react-bootstrap/Card';


const Services = () => {
    return(
          <div id='services'>
            <h1 className='fontStyleH1'>Services</h1>
            <hr className='separator'></hr>
            <div className='flex-container'>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/planning.png'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Concept development & Execution </Card.Title>
                        <Card.Text>
                            A structured way to develop an idea for Restaurant/ café/ Lounge, cloud kitchen and determine what our customer market is willing to buy our service and products
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/menu.png'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Menu Engineering & development</Card.Title>
                        <Card.Text>
                            Menu Engineering includes perception, attention, emotion, and effect highlighted in Menu Designed. as well as managing overall food cost for contribution, the margin of every Recipe cost analysis, and pricing
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/kitchen_design.png'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Kitchen Design and Set-Up</Card.Title>
                        <Card.Text>
                            Professional Kitchen Planning & Designing is a major concern before the execution of the kitchen starts. The kitchen's layout must ensure the best space utilization along with the easy movement of movable equipment in the kitchen and corridors. To ensure practical implementation of equipment in the space provided and follow the Govt Law as applicable
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/budget.png'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Project budget and oparational budget</Card.Title>
                        <Card.Text>
                            Includes permanent assets, place, equipment, staffing required, and cost for the same
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/operations.png'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Operations set-up & implementation</Card.Title>
                        <Card.Text>
                            Includes operational requirement to run the show of service industry
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/requirement.png'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Recruitment, Tasting and demonstrations</Card.Title>
                        <Card.Text>
                            Include - hiring the telant form the market by choosing professionals, recipe trade test
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/sop.png'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Manuals and SOPs</Card.Title>
                        <Card.Text>
                            Standard operating processes and manuals to maintain company vision
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className='cardFlexItem'>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + '/audit.png'} />
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Operations audits & streamlining</Card.Title>
                        <Card.Text>
                            Any organization requires more efficient and effective role employing faster or simpler working methods to achieve the goals
                        </Card.Text>
                    </Card.Body>
                </Card>
                
            </div>
          </div>
          );
}

export default Services;
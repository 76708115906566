import React from 'react';
import Card from 'react-bootstrap/Card';


const Projects = () => {
    return(
          <div>
            <h1 id="projects" className='fontStyleH1'>Projects</h1>
            <hr className='separator'></hr>
            
            <div className='flex-container'>
            
                <Card className='cardFlexItem' href='https://www.instagram.com/cafedeleila/'>
                    <a href='https://www.instagram.com/cafedeleila/' target="_blank">
                        <Card.Img variant="top" src={process.env.PUBLIC_URL + '/cafe_de_leila.png'} />
                    </a>
                    <Card.Body>
                        <Card.Title style={{ color: "#666699" }}>Cafe De Leila </Card.Title>
                        <Card.Text>
                            Designed the outdoor kitchen and bar area
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
          </div>
          );
}

export default Projects;
import React from 'react';


const AboutUs = () => {
    return (
        <div id='aboutus'>
            <h1 className='fontStyleH1'>About Us</h1>
            <hr className='separator'></hr>
            <div className='about'>
                <div>
                As a small brief to TBD Chef Consultancy Services known as TBD Holidays Private Limited, came into existence in 2018, TBD Holidays company assists with services for Menu Engineering, Commercial Kitchen Planning and Back of House facility Planning, setups, project execution, staffing and operational as per the requirement at the best possibilities.
                    TBD Holidays work closely with hotel owners for over 18 years and understands the pain points involved in a project go through during its lifecycle. TBD Holidays offers specialist business consultancy services to hotel and resort owners, Operators, Developers, Lenders, and Investors.
                    What distinguishes us from other consultancies is that being a Chef and having an experience of over 16 years after Hotel Management Graduate in my executive career before becoming a Consultant. 
                    Our consultancy offers services to businesses of all sizes, from developing small boutique hotels to helping organizations with a large portfolio of hotels improve performance and keep ahead of the game. Our knowledge and expertise in making hotels and resorts successful cannot be matched elsewhere
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
import React from 'react';


const Home = () => {
    return (
        <div id='home'>
            <div className='bg' height='1000px'>
            </div>
        </div>
    );
}

export default Home;
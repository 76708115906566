import React from 'react';

const ContactUs = () => {
    return (
        <div id='contactus'>
            <h1 className='fontStyleH1'>Contact Us</h1>
            <hr className='separator'></hr>
            <div className='flex-container'>
                <div className='cardFlexItemContactAddress'><br/>
                <h1>Address</h1>
                    TBD CHEF CONSULTANCY<br/>
                    TBD HOLIDAYS PRIVATE LIMITED<br/>
                    CIN- U63030UP2021PTC142074<br/>
                    Registered Office - Shop No. 15/12 Ground<br/>
                    Lekhraj Market, B Block,<br/>
                    Rajajipuram, Dist. - Lucknow.<br/>
                    Uttar Pradesh - 226017<br/>
                    India<br/><br/>
                </div>
                <div className='cardFlexItemContactAddress'><br/>
                    <h1>Contact information</h1>
                    Mobile- +91 9336081353<br/>
                    Email - info@tbdchefconsultancy.com<br/>               
                    <a href='https://www.youtube.com/channel/UCZZOtsPY4CeIEupaBxVg-fg' target="_blank">
                        <i class="bi bi-youtube color-red"></i> YouTube  
                    </a><br/>
                    <a href='https://www.instagram.com/tbdchefconsulatncy' target="_blank">
                        <i class="bi bi-instagram color-red"></i> Instagram
                    </a><br/>
                    <a href='https://www.linkedin.com/in/tbd-chef-consultancy-a031bb22a' target="_blank">
                        <i class="bi bi-linkedin color-blue"></i> LinkedIn
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;